<template>
    <div class="container-inner" style="min-height: 618px">
        <div class="p-image my-5 pl-3 pr-3">
            <h3 class="mb-4">
                {{ $t('invite.scan_to_join', {uname}) }}
            </h3>
            <div class="qrcode img-fluid" id="qrcode"></div>

            <div class="in-text" v-html="$t('invite.enjoy')"></div>
        </div>
        <a class="cta style1" @click="copy">
            {{ $t('invite.share') }}
        </a>
    </div>
</template>
<style>
.qrcode {
    margin: 40px auto 30px;
    width: 280px;
    height: 280px;
}
</style>
<script>
import Vue from 'vue';
import QRCode from 'qrcodejs2';
import { mapMutations, mapGetters, mapActions } from 'vuex';

// import SocialSharing from 'vue-social-sharing';
// Vue.use(SocialSharing);

export default {
    name: 'invitepeople',
    data() {
        let sid = this.$store.state.app.sid || '';
        let shared_tid = this.$store.state.app.shared_tid || '';
        let shared_table_pswd = this.$store.state.app.shared_table_pswd || '';
        let type = this.$store.state.app.type;

        let url = '';
        if (type == 'scan') {
            url =
                'https://' +
                window.location.host +
                '/#/sub/selecttable?sid=' +
                sid +
                '&tid=' +
                shared_tid +
                '&p=' +
                shared_table_pswd;
        } else if (type == 'preorder') {
            url = 'https://' + window.location.host + '/#/sub/preorder?sid=' + sid + '&tid=' + shared_tid;
        }

        return {
            url: url,
            share_config: {
                url: url,
                title: 'Minitable',
                sites: ['google', 'facebook', 'twitter', 'linkedin', 'qq', 'weibo', 'wechat']
            }
        };
    },
    created() {
        // document.getElementsByClassName('sharethis-inline-share-buttons')[0].setAttribute('data-url', this.url);
        // let share_icons = document.getElementsByClassName('st-btn');
        // for (let i = 0; i < 6; i++) {
        //     share_icons[i].style.display = 'inline-block';
        // }

        // let pos_b = document.body.clientHeight - 439 + 30;
        // let _share_dom = document.getElementById('share_dom');
        // _share_dom.style.top = '439px';
        // _share_dom.style.display = 'block';

        let _title = this.$t('invite.invite');
        this.set_app_title(_title);
    },
    beforeDestroy() {
        // let _share_dom = document.getElementById('share_dom');
        // _share_dom.style.display = 'none';
    },
    mounted() {
        this.qrcode();
    },
    computed: {
        ...mapGetters({
            language: 'app/get_language',
            uname: 'app/get_uname'
        })
    },
    methods: {
        qrcode() {
            let qrcode = new QRCode('qrcode', {
                width: 280,
                height: 280,
                text: this.url // 二维码内容
            });
        },
        copy() {
            const ta = document.createElement('textarea');
            // ta.style.display = 'none';
            ta.value = this.url;
            document.body.appendChild(ta);
            ta.style.position = 'fixed';
            ta.style.top = 0;
            ta.style.left = 0;

            ta.style.width = '2em';
            ta.style.height = '2em';

            ta.style.padding = 0;

            ta.style.border = 'none';
            ta.style.outline = 'none';
            ta.style.boxShadow = 'none';
            ta.focus();
            ta.select();
            document.execCommand('copy');
            document.body.removeChild(ta);
            const msg = this.$t('invite.url_copied')
            this.setToast({ msg });
        },
        ...mapMutations({
            set_app_title: 'app/APP_SET_APP_TITLE'
        }),
        ...mapActions({
            setToast: 'app/setToast'
        })
    }
};
</script>
